import { createActionGroup, props } from '@ngrx/store';
import { UserType, User_Permission } from 'src/app/shared/models';

export const PermissionGrantedActions = createActionGroup({
  source: 'PermissionGranted',
  events: {
    // 'Load PermissionsGranted for UserType': props<{
    //   userId: string;
    //   userType?: UserType;
    //   collectionName?: string;
    // }>(),
    'Load PermissionsGranted': props<{
      userId: string;
      userType?: UserType;
      grantedUserType?: UserType;
    }>(),
    'Load PermissionsGranted Success': props<{
      permissionsGranted: User_Permission[];
    }>(),
    'Load PermissionsGranted Failure': props<{ error: Error }>(),
  },
});
